import Link from "next/link";
import React from "react";
import { menuLinks } from "@/constants/menuLinks";

const NavMenuLinks = () => {
  return (
    <div className="hidden lg:flex lg:space-x-4 xl:space-x-8">
      {menuLinks.links.map((link) => (
        <Link key={link.label} href={link.url}>
          <a className="font-medium text-gray-200 hover:text-gray-400 lg:text-sm xl:text-base">
            {link.label}
          </a>
        </Link>
      ))}
    </div>
  );
};

export default NavMenuLinks;
