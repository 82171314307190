export const ROLES = ["Top", "Jungle", "Mid", "Bottom", "Support", "Coach"];

export const RANK = [
  "Iron",
  "Bronze",
  "Silver",
  "Gold",
  "Platinum",
  "Diamond",
  "Master",
  "Grandmaster",
  "Challenger",
];

export const GAMEMODES = [
  "Competitive",
  "Ranked Solo",
  "Ranked Flex",
  "Normal 5v5",
  "ARAM",
  "Teamfight Tactics",
];

export const DEFAULTICON =
  "https://cdn.communitydragon.org/latest/profile-icon/29";
