export const LANGUAGES = [
  {
    label: "English",
    value: "EN",
  },
  {
    label: "Dutch",
    value: "NL",
  },
];
