import React from "react";
import NavLogo from "./NavLogo";
import NavMenuLinks from "./NavMenuLinks";
import LanguageDropdown from "@/components/LanguageDropdown";
import NavAuthentication from "./NavAuthentication";

const NavDesktop = () => {
  return (
    <div className="mx-auto flex max-w-7xl items-center justify-between p-4">
      <NavLogo />
      <NavMenuLinks />
      <div className="hidden flex-1 items-center justify-end space-x-6 lg:flex">
        <LanguageDropdown />
        <NavAuthentication />
      </div>
    </div>
  );
};

export default NavDesktop;
