import React from "react";
import NavDesktop from "./desktop";
import NavMobile from "./mobile";
import { Popover } from "@headlessui/react";

const Navigation = () => {
  return (
    <Popover className="fixed top-0 z-20 w-full bg-nav shadow">
      <NavDesktop />
      <NavMobile />
    </Popover>
  );
};

export default Navigation;
