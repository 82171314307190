import React, { PropsWithChildren, ReactElement } from "react";
import Navigation from "@/components/Navigation/user/Navigation";
import Footer from "@/components/Footer";

const UserLayout = ({ children }: PropsWithChildren<{}>): ReactElement => {
  return (
    <>
      <Navigation />
      <main className="mx-auto mt-20 flex w-full max-w-7xl flex-1 flex-col px-4 py-8">
        {children}
      </main>
      <Footer />
    </>
  );
};

export default UserLayout;
