import Link from "next/link";
import { Fragment } from "react";
import { useRouter } from "next/router";
import { LANGUAGES } from "@/constants/languages";
import { classNames } from "@/utils";
import { Popover, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@/icons";

const LanguageDropdown = () => {
  const router = useRouter();
  const { locale, pathname, query, asPath } = router;

  const setCookie = (lang: string) => {
    const date = new Date();
    date.setDate(date.getDate() + 7);

    document.cookie = `NEXT_LOCALE=${lang}; expires=${date}`;
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? "" : ""}
                group inline-flex items-center rounded-md p-2 font-medium text-gray-200 hover:bg-navMobile focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="text-sm font-semibold uppercase">{locale}</span>
            <ChevronDownIcon className="ml-1" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-1/2 transform">
              <div className="flex w-fit flex-col rounded-lg border border-slate-700 bg-navMobile p-2 shadow-lg ring-1  ring-black ring-opacity-5">
                {LANGUAGES.map((lang) => (
                  <Link
                    key={lang.value}
                    href={{ pathname, query }}
                    as={asPath}
                    locale={lang.value.toLowerCase()}
                  >
                    <a
                      className={classNames(
                        "inline-flex items-center justify-between space-x-4 py-2 px-2",
                        lang.value === locale?.toUpperCase()
                          ? "text-blue-400"
                          : "text-slate-300"
                      )}
                      onClick={() => setCookie(lang.value)}
                    >
                      <span>{lang.label}</span>
                      {lang.value === locale?.toUpperCase() && <CheckIcon />}
                    </a>
                  </Link>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default LanguageDropdown;
