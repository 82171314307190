import React, { Fragment, ReactNode } from "react";
import Link from "next/link";
import Image from "next/image";
import * as ROUTES from "@/constants/routes";
import { logout } from "@/utils/auth";
import { classNames } from "@/utils";
import { DEFAULTICON } from "@/constants";
import { authMenuLinks } from "@/constants/menuLinks";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useTranslation } from "next-i18next";
import { ChevronDownIcon } from "@/icons";
import { Popover, Transition } from "@headlessui/react";

const NavAuthentication = () => {
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation("common");

  return !currentUser.roles ? (
    <div>
      <Link href={ROUTES.SIGN_IN}>
        <a className="whitespace-nowrap text-sm font-medium text-gray-200 hover:text-gray-400 xl:text-base">
          {t("signIn")}
        </a>
      </Link>
      <Link href={ROUTES.SIGN_UP}>
        <a className="ml-3 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-primary px-3 py-2 text-sm font-medium text-gray-100 shadow-sm xl:text-base">
          {t("register")}
        </a>
      </Link>
    </div>
  ) : (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-500",
              "group inline-flex items-center space-x-2 text-base font-medium hover:text-gray-900"
            )}
          >
            <div className="flex place-content-center rounded-full border-2 border-slate-300">
              <Image
                src={
                  currentUser.mainSummoner?.icon
                    ? currentUser.mainSummoner?.icon
                    : DEFAULTICON
                }
                height={32}
                width={32}
                className="rounded-full"
                alt=""
              />
            </div>
            <p className="text-sm font-medium text-slate-300 xl:text-base">
              {currentUser.mainSummoner?.name ? (
                <span className="block max-w-[15ch] overflow-hidden text-ellipsis whitespace-nowrap">
                  {currentUser.mainSummoner?.name}
                </span>
              ) : (
                <span className="block max-w-[15ch] overflow-hidden text-ellipsis whitespace-nowrap">
                  {currentUser.username}
                </span>
              )}
            </p>
            <ChevronDownIcon className="text-slate-300" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-2 w-screen -translate-x-1/2 px-2 sm:px-0 lg:w-48">
              <div className="overflow-hidden rounded-lg border border-slate-700 shadow-lg ring-1  ring-black ring-opacity-5">
                <div className="relative bg-navMobile p-5">
                  {/* Authenticated user */}
                  <NavDesktopSection sectionName={authMenuLinks.role}>
                    {authMenuLinks.links.map((link) => (
                      <NavDesktopLink
                        key={link.label}
                        label={link.label}
                        url={link.url}
                      />
                    ))}
                  </NavDesktopSection>

                  {/* Super admin */}
                  {currentUser.roles?.includes("ROLE_SUPER_ADMIN") ? (
                    <NavDesktopSection sectionName="Admin">
                      <NavDesktopLink
                        label="Dashboard"
                        url={`${ROUTES.ADMIN_DASHBOARD}/users`}
                      />
                    </NavDesktopSection>
                  ) : null}

                  {/* Community admin */}
                  {currentUser.manageCommunities?.length ? (
                    <NavDesktopSection sectionName="Community Admin">
                      {currentUser.manageCommunities.map((community) => (
                        <NavDesktopLink
                          key={community.id}
                          label={community.name}
                          url={`/community/dashboard/${community.id}/competitions`}
                        />
                      ))}
                    </NavDesktopSection>
                  ) : null}

                  <Divider />
                  <button
                    className="text-sm font-medium text-gray-300 hover:text-gray-400"
                    onClick={logout}
                  >
                    {t("signOut")}
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

const Divider = () => (
  <hr className="mt-2 border-0 border-t-2 border-[hsl(220,24%,24%)] pt-2" />
);

const NavDesktopSection: React.FC<{
  sectionName: string;
  children: ReactNode;
}> = ({ sectionName, children }) => {
  return (
    <div className="mb-8">
      <p className="text-ellipsis text-xs font-semibold uppercase tracking-wide text-blue-400">
        {sectionName}
      </p>
      <Divider />
      <div className="grid gap-3">{children}</div>
    </div>
  );
};

const NavDesktopLink: React.FC<{ label: string; url: string }> = ({
  label,
  url,
}) => {
  return (
    <Link href={url}>
      <a className="text-sm font-medium text-gray-300 hover:text-gray-400">
        {label}
      </a>
    </Link>
  );
};

export default NavAuthentication;
