import React, { Fragment, ReactNode } from "react";
import Link from "next/link";
import Image from "next/image";
import * as ROUTES from "@/constants/routes";
import { authMenuLinks, menuLinks } from "@/constants/menuLinks";
import { Popover, Transition } from "@headlessui/react";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useTranslation } from "next-i18next";
import { CloseIcon } from "@/icons";
import { logout } from "@/utils/auth";

const NavMobile = () => {
  const { t } = useTranslation("common");
  const { currentUser } = useCurrentUser();

  return (
    <>
      <Popover.Overlay as="div" className="fixed inset-0 bg-black opacity-80" />
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition lg:hidden"
        >
          <div className="space-y-4 rounded-lg bg-navMobile px-6 py-4 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-center">
                <Image
                  src={`/images/LeagueConnect_logo.webp`}
                  height={28}
                  width={28}
                  alt="LeagueConnect"
                />
                <span className="ml-3 text-2xl font-semibold leading-3 text-white">
                  LeagueConnect
                </span>
              </div>
              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Close menu</span>
                  <CloseIcon className="mt-2 h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>

            <div className="space-y-4">
              {/* General links */}
              <NavMobileSection sectionName={menuLinks.role}>
                {menuLinks.links.map((link) => (
                  <NavMobileLink
                    key={link.label}
                    label={link.label}
                    url={link.url}
                  />
                ))}
              </NavMobileSection>

              {/* Authenticated user */}
              {currentUser.roles && (
                <NavMobileSection sectionName={authMenuLinks.role}>
                  {authMenuLinks.links.map((link) => (
                    <NavMobileLink
                      key={link.label}
                      label={link.label}
                      url={link.url}
                    />
                  ))}
                </NavMobileSection>
              )}

              {/* Super admin */}
              {currentUser.roles?.includes("ROLE_SUPER_ADMIN") ? (
                <NavMobileSection sectionName="Admin">
                  <NavMobileLink
                    label="Dashboard"
                    url={`${ROUTES.ADMIN_DASHBOARD}/users`}
                  />
                </NavMobileSection>
              ) : null}

              {/* Community admin */}
              {currentUser.manageCommunities?.length ? (
                <NavMobileSection sectionName="Community Admin">
                  {currentUser.manageCommunities.map((community) => (
                    <NavMobileLink
                      key={community.id}
                      label={community.name}
                      url={`/community/dashboard/${community.id}/competitions`}
                    />
                  ))}
                </NavMobileSection>
              ) : null}
            </div>

            {!currentUser.roles ? (
              <div>
                <Link href={ROUTES.SIGN_UP}>
                  <a className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm">
                    {t("register")}
                  </a>
                </Link>
                <p className="mt-4 text-center text-sm font-medium text-gray-500">
                  Already have an account?{" "}
                  <Link href={ROUTES.SIGN_IN}>
                    <a className="text-blue-400">{t("signIn")}</a>
                  </Link>
                </p>
              </div>
            ) : (
              <div>
                <hr className="mt-8 border-0 border-t-2 border-[hsl(220,24%,24%)] pb-3" />
                <button
                  className="font-medium text-gray-300 hover:text-gray-400"
                  onClick={logout}
                >
                  {t("signOut")}
                </button>
              </div>
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </>
  );
};

const NavMobileSection: React.FC<{
  sectionName: string;
  children: ReactNode;
}> = ({ sectionName, children }) => {
  return (
    <div className="py-2">
      <p className="mb-2 border-b-2 border-[hsl(220,24%,24%)] pb-2 text-sm font-semibold text-blue-400">
        {sectionName}
      </p>
      <div className="grid grid-cols-2 gap-4">{children}</div>
    </div>
  );
};

const NavMobileLink: React.FC<{ label: string; url: string }> = ({
  label,
  url,
}) => {
  return (
    <Link href={url}>
      <a className="block w-fit text-sm font-medium text-gray-300 hover:text-gray-400">
        {label}
      </a>
    </Link>
  );
};

export default NavMobile;
