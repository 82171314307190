import * as ROUTES from "./routes";

export const menuLinks = {
  role: "Web Links",
  links: [
    { label: "Teamfinder", url: ROUTES.TEAMFINDER },
    { label: "Clash | Competitions", url: ROUTES.COMPETITIONS_OVERVIEW },
    { label: "Communities", url: ROUTES.COMMUNITIES_OVERVIEW },
    { label: "Contact", url: ROUTES.CONTACT },
  ],
};

export const authMenuLinks = {
  role: "User",
  links: [
    { label: "Profile", url: ROUTES.USER_EDIT },
    { label: "Dashboard", url: ROUTES.USER_DASHBOARD },
  ],
};
