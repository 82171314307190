import React from "react";
import Link from "next/link";
import { DiscordIcon, TwitchIcon, TwitterIcon, FacebookIcon } from "@/icons";

const Footer = () => {
  return (
    <footer className="bg-black">
      <div className="mx-auto flex max-w-7xl flex-col items-center bg-black px-4 py-8 lg:flex-row">
        <div className="text-center text-gray-500 lg:text-left">
          <small className="block">
            © {new Date().getFullYear()} LeagueConnect - League of Legends
            community hub
          </small>
          <small className="block">
            LeagueConnect isn’t endorsed by Riot Games and doesn’t reflect the
            views or opinions of Riot Games or anyone officially involved in
            producing or managing League of Legends. League of Legends and Riot
            Games are trademarks or registered trademarks of Riot Games, Inc.
            League of Legends © Riot Games, Inc.
          </small>
        </div>
        <ul className="mt-4 flex w-fit lg:ml-12">
          <SocialLink url="/discord" icon={<DiscordIcon />} />
          <SocialLink url="/twitch" icon={<TwitchIcon />} />
          <SocialLink url="/facebook" icon={<FacebookIcon />} />
          <SocialLink url="/twitter" icon={<TwitterIcon />} />
        </ul>
      </div>
    </footer>
  );
};

type SocialLinkProps = {
  url: string;
  icon: React.ReactNode;
};

const SocialLink = ({ url, icon }: SocialLinkProps) => (
  <li className="w-fit rounded-full p-3 text-slate-200 hover:text-gray-400">
    <Link href={url ?? ""}>
      <a className="text-2xl" target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    </Link>
  </li>
);

export default Footer;
