export const toCapitalize = (word: string) => {
  return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
};

export const formatRank = (rank: string) => {
  if (rank === "0") return "Unranked";

  if (typeof rank !== "string") return;

  const placement = rank.split("_");

  const tier =
    placement[0][0].toUpperCase() + placement[0].slice(1).toLowerCase();
  const division = placement[1];

  return placement.length > 1 ? `${tier} ${division}` : tier;
};

export const commaSeparatedList = (list: string[]) => {
  let items = "";

  list.map((listItem, index, arr) => {
    const item = listItem[0].toUpperCase() + listItem.slice(1).toLowerCase();
    items = items.concat(`${item}${index !== arr.length - 1 ? `, ` : ""}`);
  });

  return items;
};

export const convert2base64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};
