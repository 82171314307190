import React from "react";
import Link from "next/link";
import Image from "next/image";
import LanguageDropdown from "@/components/LanguageDropdown";
import { HOME } from "@/constants/routes";
import { Popover } from "@headlessui/react";
import { BurgerIcon } from "@/icons";

const NavLogo = () => (
  <div className="flex flex-1 justify-between">
    <Link href={HOME}>
      <a className="flex items-center text-white">
        <span className="sr-only">LeagueConnect</span>
        <Image
          className="h-8 w-auto sm:h-10"
          src={`/images/LeagueConnect_logo.webp`}
          height={32}
          width={32}
          alt=""
        />
        <span className="ml-3 text-xl font-semibold leading-3 xl:text-2xl">
          LeagueConnect
        </span>
      </a>
    </Link>
    <div className="flex items-center">
      <div className="mr-6 lg:hidden">
        <LanguageDropdown />
      </div>
      <div className="-my-2 lg:hidden">
        <Popover.Button className="inline-flex items-center justify-center rounded-md py-2 text-gray-300 hover:text-gray-500">
          <span className="sr-only">Open menu</span>
          <BurgerIcon className="h-8 w-8" aria-hidden="true" />
        </Popover.Button>
      </div>
    </div>
  </div>
);

export default NavLogo;
